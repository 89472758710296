import React, { useEffect, useState } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { MenuItem, PageTitle, Option, Menu, OptionTitle } from "./styles";
import PageContainer from "../../../Components/PageContainer";
import Chart from "react-apexcharts";
import { PageOptions } from "./styles";
import api from "../../../Services/api";
import {
	compraInfo,
	dataCompraAno,
	dataCompraMes,
	dataCompraSemana,
	dataEspecialidade,
	dataUsoAno,
	dataUsoMes,
	dataUsoSemana,
	optionsAcessosMes,
	optionsCompra,
	optionsDonwloadsMes,
	optionsEspecialidade,
	optionsPacientes,
	optionsUso,
	pacientesInfo,
	paradoInfo,
	prestadoresInfo,
	tempoInfo,
	usoInfo,
} from "./data";
import MenuMetricas from "../../../Components/MenuMetricas";
import MetricInforCard from "../../../Components/MetricInfoCard";

export default function MetricasCreditos() {
	const [loading, setLoading] = useState(false);
	const [graficoCompra, setGraficoCompra] = useState("semana");
	const [graficoUso, setGraficoUso] = useState("semana");
	const [dadosCompra, setDadosCompra] = useState(dataCompraSemana);
	const [dadosUso, setDadosUso] = useState(dataUsoSemana);

	const onPageStyle = {
		color: "#f3f3f3",
		backgroundColor: "#663780",
	};

	useEffect(() => {
		const getCredits = async () => {
			setLoading(true);
			try {
				const response = await api.get("/creditos/total");
				const { total_creditos_plataforma } = response.data;
				console.log(total_creditos_plataforma);
				console.log(response.data);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getCredits();
	}, []);

	function handleClickCompra(tipo, dados) {
		setGraficoCompra(tipo);
		setDadosCompra(dados);
	}

	function handleClickUso(tipo, dados) {
		setGraficoUso(tipo);
		setDadosUso(dados);
	}

	return (
		<Page>
			<SidebarMenu />
			<PageContainer>
				<PageTitle>Métricas</PageTitle>
				<PageOptions>
					<MenuMetricas></MenuMetricas>

					<Option>
						<OptionTitle>Compra de créditos</OptionTitle>
						<MetricInforCard data={compraInfo}></MetricInforCard>
						<Menu>
							<MenuItem
								onClick={() => handleClickCompra("semana", dataCompraSemana)}
								className={` ${
									graficoCompra === "semana" ? "bg-roxo text-white" : "bg-white text-roxo"
								}`}
							>
								Semana
							</MenuItem>
							<MenuItem
								onClick={() => handleClickCompra("mes", dataCompraMes)}
								className={` ${
									graficoCompra === "mes" ? "bg-roxo text-white" : "bg-white text-roxo"
								}`}
							>
								Mês
							</MenuItem>
							<MenuItem
								onClick={() => handleClickCompra("ano", dataCompraAno)}
								className={` ${
									graficoCompra === "ano" ? "bg-roxo text-white" : "bg-white text-roxo"
								}`}
							>
								Ano
							</MenuItem>
						</Menu>
						<Chart
							options={optionsCompra.options}
							series={dadosCompra}
							type="line"
							height={350}
						></Chart>
					</Option>
					<Option>
						<OptionTitle>Uso de créditos</OptionTitle>
						<MetricInforCard data={usoInfo}></MetricInforCard>
						<Menu>
							<MenuItem
								onClick={() => handleClickUso("semana", dataUsoSemana)}
								className={` ${
									graficoUso === "semana" ? "bg-roxo text-white" : "bg-white text-roxo"
								}`}
							>
								Semana
							</MenuItem>
							<MenuItem
								onClick={() => handleClickUso("mes", dataUsoMes)}
								className={` ${graficoUso === "mes" ? "bg-roxo text-white" : "bg-white text-roxo"}`}
							>
								Mês
							</MenuItem>
							<MenuItem
								onClick={() => handleClickUso("ano", dataUsoAno)}
								className={` ${graficoUso === "ano" ? "bg-roxo text-white" : "bg-white text-roxo"}`}
							>
								Ano
							</MenuItem>
						</Menu>
						<Chart options={optionsUso.options} series={dadosUso} type="line" height={350}></Chart>
					</Option>
					<Option>
						<OptionTitle>Uso por especialidade</OptionTitle>
						<Chart
							options={optionsEspecialidade.options}
							series={dataEspecialidade}
							type="bar"
							height={350}
						></Chart>
					</Option>
					<Option>
						<OptionTitle>Créditos parados</OptionTitle>
						<MetricInforCard data={paradoInfo}></MetricInforCard>
					</Option>
					<Option>
						<OptionTitle>Tempo de créditos parados</OptionTitle>
						<MetricInforCard data={tempoInfo}></MetricInforCard>
					</Option>
				</PageOptions>
			</PageContainer>
		</Page>
	);
}
