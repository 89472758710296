const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const compraInfo = [
	{
		title: "Mês atual",
		value: 2.801,
		icon: (
			<svg
				width="28"
				height="49"
				viewBox="0 0 28 49"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.0565 21.4505C8.90859 19.8526 6.93151 18.2005 6.93151 15.6276C6.93151 12.6755 9.66693 10.6172 14.244 10.6172C19.0648 10.6172 20.8523 12.9193 21.0148 16.3047H27.0003C26.8107 11.6464 23.9669 7.36719 18.3065 5.98594V0.0546875H10.1815V5.90469C4.92734 7.04219 0.702343 10.4547 0.702343 15.6818C0.702343 21.938 5.87526 25.0526 13.4315 26.8672C20.2023 28.4922 21.5565 30.8755 21.5565 33.3943C21.5565 35.263 20.2294 38.2422 14.244 38.2422C8.66484 38.2422 6.47109 35.7505 6.17318 32.5547H0.214844C0.539844 38.4859 4.98151 41.8172 10.1815 42.9276V48.8047H18.3065V42.9818C23.5878 41.9797 27.7857 38.9193 27.7857 33.3672C27.7857 25.6755 21.2044 23.0484 15.0565 21.4505Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
];

export const usoInfo = [
	{
		title: "Total",
		value: 2.801,
		icon: (
			<svg
				width="28"
				height="49"
				viewBox="0 0 28 49"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.0565 21.4505C8.90859 19.8526 6.93151 18.2005 6.93151 15.6276C6.93151 12.6755 9.66693 10.6172 14.244 10.6172C19.0648 10.6172 20.8523 12.9193 21.0148 16.3047H27.0003C26.8107 11.6464 23.9669 7.36719 18.3065 5.98594V0.0546875H10.1815V5.90469C4.92734 7.04219 0.702343 10.4547 0.702343 15.6818C0.702343 21.938 5.87526 25.0526 13.4315 26.8672C20.2023 28.4922 21.5565 30.8755 21.5565 33.3943C21.5565 35.263 20.2294 38.2422 14.244 38.2422C8.66484 38.2422 6.47109 35.7505 6.17318 32.5547H0.214844C0.539844 38.4859 4.98151 41.8172 10.1815 42.9276V48.8047H18.3065V42.9818C23.5878 41.9797 27.7857 38.9193 27.7857 33.3672C27.7857 25.6755 21.2044 23.0484 15.0565 21.4505Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
	{
		title: "Média por titular",
		value: 1.234,
		icon: (
			<svg
				width="44"
				height="45"
				viewBox="0 0 44 45"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M22.318 25.1387C15.0868 25.1387 0.651367 28.7678 0.651367 35.972V44.097H43.9847V35.972C43.9847 28.7678 29.5493 25.1387 22.318 25.1387ZM38.8389 38.9512H5.7972V35.972C5.7972 34.2387 14.2743 30.2845 22.318 30.2845C30.3618 30.2845 38.8389 34.2387 38.8389 35.972V38.9512ZM22.318 22.4303C28.3035 22.4303 33.1514 17.5824 33.1514 11.597C33.1514 5.61159 28.3035 0.763672 22.318 0.763672C16.3326 0.763672 11.4847 5.61159 11.4847 11.597C11.4847 17.5824 16.3326 22.4303 22.318 22.4303ZM22.318 5.90951C25.4597 5.90951 28.0055 8.45534 28.0055 11.597C28.0055 14.7387 25.4597 17.2845 22.318 17.2845C19.1764 17.2845 16.6305 14.7387 16.6305 11.597C16.6305 8.45534 19.1764 5.90951 22.318 5.90951Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
	{
		title: "Mês atual",
		value: 1.567,
		icon: (
			<svg
				width="66"
				height="66"
				viewBox="0 0 66 66"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M54.4854 9.05534H51.777V3.63867H46.3604V9.05534H19.277V3.63867H13.8604V9.05534H11.152C8.17285 9.05534 5.73535 11.4928 5.73535 14.472V57.8053C5.73535 60.7845 8.17285 63.222 11.152 63.222H54.4854C57.4645 63.222 59.902 60.7845 59.902 57.8053V14.472C59.902 11.4928 57.4645 9.05534 54.4854 9.05534ZM54.4854 57.8053H11.152V22.597H54.4854V57.8053Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
];

export const paradoInfo = [
	{
		title: "Total",
		value: 2.801,
		icon: (
			<svg
				width="28"
				height="49"
				viewBox="0 0 28 49"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.0565 21.4505C8.90859 19.8526 6.93151 18.2005 6.93151 15.6276C6.93151 12.6755 9.66693 10.6172 14.244 10.6172C19.0648 10.6172 20.8523 12.9193 21.0148 16.3047H27.0003C26.8107 11.6464 23.9669 7.36719 18.3065 5.98594V0.0546875H10.1815V5.90469C4.92734 7.04219 0.702343 10.4547 0.702343 15.6818C0.702343 21.938 5.87526 25.0526 13.4315 26.8672C20.2023 28.4922 21.5565 30.8755 21.5565 33.3943C21.5565 35.263 20.2294 38.2422 14.244 38.2422C8.66484 38.2422 6.47109 35.7505 6.17318 32.5547H0.214844C0.539844 38.4859 4.98151 41.8172 10.1815 42.9276V48.8047H18.3065V42.9818C23.5878 41.9797 27.7857 38.9193 27.7857 33.3672C27.7857 25.6755 21.2044 23.0484 15.0565 21.4505Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
];

export const tempoInfo = [
	{
		title: "Tempo médio",
		value: 2.801,
		icon: (
			<svg
				width="66"
				height="66"
				viewBox="0 0 66 66"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M32.9732 6.3457C18.0232 6.3457 5.91699 18.479 5.91699 33.429C5.91699 48.379 18.0232 60.5124 32.9732 60.5124C47.9503 60.5124 60.0837 48.379 60.0837 33.429C60.0837 18.479 47.9503 6.3457 32.9732 6.3457ZM41.9107 46.1853L30.292 34.5395V19.8874H35.7087V32.3186L45.7566 42.3665L41.9107 46.1853Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
];

export const dataCompraSemana = [
	{
		name: "Créditos Comprados",
		data: [
			{
				x: "Semana 1",
				y: 50,
			},
			{
				x: "Semana 2",
				y: 54,
			},
			{
				x: "Semana 3",
				y: 74,
			},
			{
				x: "Semana 4",
				y: 81,
			},
		],
	},
];

export const dataCompraMes = [
	{
		name: "Créditos Comprados",
		data: [
			{
				x: "Jan",
				y: 300,
			},
			{
				x: "Fev",
				y: 354,
			},
			{
				x: "Mar",
				y: 270,
			},
			{
				x: "Abr",
				y: 410,
			},
			{
				x: "Mai",
				y: 500,
			},
			{
				x: "Jun",
				y: 399,
			},
			{
				x: "Jul",
				y: 489,
			},
			{
				x: "Ago",
				y: 503,
			},
			{
				x: "Set",
				y: 459,
			},
			{
				x: "Out",
				y: 478,
			},
			{
				x: "Nov",
				y: 432,
			},
			{
				x: "Dez",
				y: 490,
			},
		],
	},
];

export const dataCompraAno = [
	{
		name: "Créditos Comprados",
		data: [
			{
				x: "2020",
				y: 1300,
			},
			{
				x: "2021",
				y: 1054,
			},
			{
				x: "2022",
				y: 1270,
			},
			{
				x: "2023",
				y: 1210,
			},
			{
				x: "2024",
				y: 1340,
			},
		],
	},
];

export const optionsCompra = {
	options: {
		colors: ["#663780"],
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 3,
		},
		title: {
			text: "Número de créditos comprados",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			type: "category",
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 500,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
	},
};

export const dataUsoSemana = [
	{
		name: "Créditos Utilizados",
		data: [
			{
				x: "Semana 1",
				y: 50,
			},
			{
				x: "Semana 2",
				y: 54,
			},
			{
				x: "Semana 3",
				y: 74,
			},
			{
				x: "Semana 4",
				y: 81,
			},
		],
	},
];

export const dataUsoMes = [
	{
		name: "Créditos Utilizados",
		data: [
			{
				x: "Jan",
				y: 300,
			},
			{
				x: "Fev",
				y: 354,
			},
			{
				x: "Mar",
				y: 270,
			},
			{
				x: "Abr",
				y: 410,
			},
			{
				x: "Mai",
				y: 500,
			},
			{
				x: "Jun",
				y: 399,
			},
			{
				x: "Jul",
				y: 489,
			},
			{
				x: "Ago",
				y: 503,
			},
			{
				x: "Set",
				y: 459,
			},
			{
				x: "Out",
				y: 478,
			},
			{
				x: "Nov",
				y: 432,
			},
			{
				x: "Dez",
				y: 490,
			},
		],
	},
];

export const dataUsoAno = [
	{
		name: "Créditos Utilizados",
		data: [
			{
				x: "2020",
				y: 1300,
			},
			{
				x: "2021",
				y: 1054,
			},
			{
				x: "2022",
				y: 1270,
			},
			{
				x: "2023",
				y: 1210,
			},
			{
				x: "2024",
				y: 1340,
			},
		],
	},
];

export const optionsUso = {
	options: {
		colors: ["#663780"],
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 3,
		},
		title: {
			text: "Número de créditos utilizados",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			categories: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 300,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
	},
};

export const dataEspecialidade = [
	{
		name: "Especialidade",
		data: [
			{
				x: "Jan",
				y: 300,
			},
			{
				x: "Fev",
				y: 354,
			},
			{
				x: "Mar",
				y: 270,
			},
			{
				x: "Abr",
				y: 410,
			},
			{
				x: "Mai",
				y: 500,
			},
			{
				x: "Jun",
				y: 399,
			},
			{
				x: "Jul",
				y: 489,
			},
			{
				x: "Ago",
				y: 503,
			},
			{
				x: "Set",
				y: 459,
			},
		],
	},
];

export const optionsEspecialidade = {
	options: {
		colors: ["#663780"],
		chart: {
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				borderRadiusApplication: "end",
				columnWidth: "50%",
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"],
		},

		title: {
			text: "Número de créditos utilizados",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			type: "category",
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 300,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
	},
};
