import styled from "styled-components";
import grafismo from "../../../Assets/grafismo.png";

export const PageContainerDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 82vw;
	height: 100%;
	min-height: 90vh;
	align-items: center;
	padding-left: max(18vw, 200px);
`;

export const RightDiv = styled.div`
	//color: ${(props) => props.theme.colors.amarelo};
	display: flex;
	flex-direction: column;
	align-items: center;
	width: ${(props) => (props.width ? props.width : "80%")};
	height: ${(props) => (props.height ? props.height : "80%")};
	gap: ${(props) => (props.gap ? props.gap : "7vh")};
	padding: 2%;
	background-color: ${(props) => props.theme.colors.roxo};
	border-radius: 15px;
	min-height: 550px;
`;

export const Background = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	background-image: url("${grafismo}"), url("${grafismo}");
	background-position: top -150px left -150px, bottom -150px right -150px;
	background-repeat: no-repeat, no-repeat;
	background-size: 55%;

	//min-width: 650px;
	//min-height: 550px;
`;

export const Redefinir = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	flex-direction: row;
`;

export const PacientInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const InfoBlock = styled.p`
	display: flex;
	flex-direction: column;
`;

export const InfoTitle = styled.h3`
	color: ${(props) => props.theme.colors.verdeClaro};
`;

export const Info = styled.p`
	font-size: large;
	color: ${(props) => props.theme.colors.brancoID};
`;

export const Content = styled.div`
	display: flex;
	gap: 100px;
`;

export const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: center;
	gap: 7vh;
	align-items: center;
	margin-left: 20vw;
	padding-bottom: 5%;
`;

export const PageTitle = styled.h1`
	font-weight: bold;
	color: ${(props) => props.theme.colors.amarelo};
	border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
	padding-top: 3%;
`;

export const Status = styled.div`
	display: flex;
	padding: 5px 10px;
	background-color: red;
	color: white;
	font-size: large;

	border-radius: 5px;
	align-items: center;
	justify-content: center;
`;

export const MissingInfo = styled.p`
	font-size: large;
	font-style: italic;
	color: ${(props) => props.theme.colors.brancoID};
`;

export const InputEdit = styled.input`
	width: 15vw;
	height: 30px;
	border-radius: 10px;
	padding-left: 4px;
	padding-right: 4px;
`;
