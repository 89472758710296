import React, { useEffect, useState } from "react";
import { Page } from "../../SignedOut/Cadastro/styles";
import SidebarMenu from "../../../Components/SidebarMenu";
import { useParams } from "react-router-dom";
import api from "../../../Services/api";
import {
  InputCPF,
  InputDescription,
  SearchButton,
  InfosContainer,
  InfosText,
  LoadingText,
  PaymentButton,
  InputAndButtonContainer,
  PageTitle,
  Left,
  Right,
  TitleText,
} from "./styles";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useAuth } from "../../../Hooks/useAuth";
import PageContainer from "../../../Components/PageContainer";

function Pagamento() {
  const [isLoadingPacient, setIsLoadingPacient] = useState(false);
  const [isLoadingDependente, setIsLoadingDependente] = useState(false);
  const [isLoadingPaymentPacient, setIsLoadingPaymentPacient] = useState(false);
  const [isLoadingPaymentDependente, setIsLoadingPaymentDependente] = useState(false);
  const [paciente, setPaciente] = useState(null);
  const [afiliado, setAfiliado] = useState(null);
  const [cpf, setCPF] = useState(null);
  const [cpf_paciente, setCpfPaciente] = useState(null);
  const [cpf_afiliado, setCpfAfiliado] = useState(null);
  const [token, setToken] = useState(null);

  const { user } = useAuth();
  console.log("user", user);

  const handleSearchPacient = async () => {
    try {
      setIsLoadingPacient(true);
      const cleanCpf = cpf.replace(/\D/g, "");
      const response = await api.get(`/pacientes/${cleanCpf}`);
      setPaciente(response.data);
      console.log("paciente", response.data);
      setIsLoadingPacient(false);
    } catch (error) {
      console.log(error);
      const showToastMessageError = () => {
        toast.error(`Não foi possível encontrar o paciente`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessageError();
      setIsLoadingPacient(false);
      setPaciente(null)
    }
  };

  const handleSearchAfiliado = async () => {
    try {
      setIsLoadingDependente(true);
      const cleanCpf = cpf_afiliado.replace(/\D/g, "");
      const response = await api.get(`/afiliado/${cleanCpf}`);
      setAfiliado(response.data);
      console.log("afiliado", response.data);
      setIsLoadingDependente(false);
    } catch (error) {
      console.log(error);
      const showToastMessageError = () => {   
        toast.error(`Não foi possível encontrar o afiliado`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessageError();
      setIsLoadingDependente(false);
      setAfiliado(null)
    }
  };

  const handleGeneratePaymentPacient = async () => {
    try {
      setIsLoadingPaymentPacient(true);
      console.log("paciente", paciente);
      console.log("user?.medico?.id", user?.medico?.id);
      console.log("paciente?.paciente?.cpf", paciente?.paciente?.cpf);
      const response = await api.post(`/transferencia/gerar`, {
        medico_id: user?.id,
        cpf_paciente: paciente?.paciente?.cpf,
      });
      setToken(response.data.token);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPaymentPacient(false);
    }
  };

  const handleGeneratePaymentAfiliado = async () => {
    try {
      setIsLoadingPaymentDependente(true);
      console.log("afiliado", afiliado);
      console.log("user?.medico?.id", user?.medico?.id);
      console.log("afiliado?.cpf_afiliado", afiliado?.cpf_afiliado);
      const response = await api.post(`/transferencia/gerar`, {
        medico_id: user?.id,
        cpf_paciente: afiliado?.cpf_afiliado,
        status: "Pendente",
      });
      setToken(response.data.token);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPaymentDependente(false);
    }
  };

  // useEffect(() => {
  //   if (cpf) {
  //     handleSearch();
  //   }
  // }, [cpf]);

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Gerar Pagamento</PageTitle>

        {/* Barra de pesquisa para o paciente */}
        <InputDescription>Gere o pagamento do PACIENTE</InputDescription>
        <InputAndButtonContainer>
          <InputCPF
            placeholder="Digite o CPF do paciente"
            mask="999.999.999-99"
            maskChar={null}
            value={cpf}
            onChange={(event) => setCPF(event.target.value)}
          />
          <SearchButton onClick={handleSearchPacient}>Pesquisar</SearchButton>
        </InputAndButtonContainer>

        {isLoadingPacient ? (
          <LoadingText>Carregando perfil do paciente...</LoadingText>
        ) : paciente ? (
          <InfosContainer>
            <Left>
              <InfosText>
                Nome: <TitleText>{paciente.nome}</TitleText>
              </InfosText>
              <InfosText>
                Email:
                <TitleText>{paciente.email}</TitleText>
              </InfosText>
              <InfosText>
                CPF:
                <TitleText>{paciente.paciente.cpf}</TitleText>
              </InfosText>
              <InfosText>
                Telefone:
                <TitleText>{paciente.paciente.telefone}</TitleText>
              </InfosText>
            </Left>
            <Right>
              <InfosText>
                Valor da Consulta:
                <TitleText>
                  {user.medico?.creditos_consulta
                    ? user.medico?.creditos_consulta
                    : user.vendedor.creditos_consulta}
                </TitleText>
              </InfosText>
              {isLoadingPaymentPacient ? (
                <LoadingText>...</LoadingText>
              ) : (
                token && (
                  <InfosText>
                    Token de Pagamento: <TitleText>{token}</TitleText>
                  </InfosText>
                )
              )}

              <PaymentButton onClick={handleGeneratePaymentPacient}>
                Gerar Pagamento
              </PaymentButton>
            </Right>
          </InfosContainer>
        ) : null}

        <InputDescription>Gere o pagamento do DEPENDENTE</InputDescription>
        <InputAndButtonContainer>
          <InputCPF
            placeholder="Digite o CPF do dependente"
            mask="999.999.999-99"
            maskChar={null}
            value={cpf_afiliado}
            onChange={(event) => setCpfAfiliado(event.target.value)}
          />
          <SearchButton onClick={handleSearchAfiliado}>Pesquisar</SearchButton>
        </InputAndButtonContainer>

        {isLoadingDependente ? (
          <LoadingText>Carregando perfil...</LoadingText>
        ) : afiliado ? (
          <InfosContainer>
            <Left>
              <InfosText>
                Nome: <TitleText>{afiliado.nome}</TitleText>
              </InfosText>
              <InfosText>
                Sexo: <TitleText>{afiliado.sexo}</TitleText>
              </InfosText>
              <InfosText>
                CPF: <TitleText>{afiliado.cpf_afiliado}</TitleText>
              </InfosText>
              <InfosText>
                Data de nascimento: <TitleText>{afiliado.data_nascimento}</TitleText>
              </InfosText>
            </Left>
            <Right>
              {isLoadingPaymentDependente ? (
                <LoadingText>...</LoadingText>
              ) : (
                token && (
                  <InfosText>
                    Token de Pagamento: <TitleText>{token}</TitleText>
                  </InfosText>
                )
              )}
              <PaymentButton onClick={handleGeneratePaymentAfiliado}>
                Gerar Pagamento
              </PaymentButton>
            </Right>
          </InfosContainer>
        ) : null}
      </PageContainer>
    </Page>
  );
}

export default Pagamento;