import React, { useState } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { RegisterContainer, RegisterButton, ColumnRow1 } from "./styles";
import { PageTitle } from "../Creditos/styles";
import ReactLoading from "react-loading";
import { useHistory } from "react-router";
import api from "../../../Services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"; // Import axios library
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import IdentityPhotoUploader from "./dropzone";
import PageContainer from "../../../Components/PageContainer";
import Input from "../../../Components/input";
import CreatableSelect from "react-select/creatable";
import { Label } from "../../../Components/style";

export default function CadastrarMedico() {
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState([]);
	const history = useHistory();

	async function sendToStorage(state) {
		try {
			const uriArray = identityPhoto.name.split(".");
			const tipoImagem = uriArray[uriArray.length - 1];

			const firstResponse = await api.get(`/put_url/${tipoImagem}`);

			const fileName = firstResponse.data.file_key;
			const mediaUrl = firstResponse.data.put_url;

			const secondResponse = await axios.put(mediaUrl, state, {
				headers: {
					"Content-Type": tipoImagem,
					/* "Content-Disposition": "attachment",
          "x-amz-acl": "public-read", */
				},
			});

			return fileName;
		} catch (err) {
			console.log("erro na sec:", err);
		}
	}

	const sendData = async (values) => {
		setLoading(true);
		try {
			const fileName = await sendToStorage(identityPhoto);
			const fileProfile = await sendToStorage(profilePhoto);
			const data = {
				nome: values["Nome completo"],
				email: values["Email"],
				senha: values["Senha"],
				tipo: "medico",
				img_url: fileName,
				bairro: values["Bairro"],
				cidade: values["Cidade"],
				estado: values["Estado"],
				medico: {
					endereco: values["Endereço"],
					cpf: values["CPF"].replace(/\D/g, ""),
					numero_de_registro_profissional: values["Número de registro profissional"],
					telefone: values["Contato"].replace(/\D/g, ""),
					creditos_consulta: 1,
					profile_image: fileProfile,

					especialidades: values["Especialidades"].map((objeto) => {
						return { nome: objeto.value };
					}),
				},
				dados_bancarios: {
					nome_banco: values["Banco"],
					numero_agencia: values["Agência"],
					numero_conta: values["Conta"],
					digito_conta: values["Digito da conta"],
					cep: values["CEP"].replace(/\D/g, ""),
					tipo_chave: values["Tipo chave"],
					chave_pix: values["Chave PIX"],
					address: values["Endereço"],
					neighbourhood: values["Bairro"],
					city: values["Cidade"],
					state: values["Estado"],
				},
			};

			console.log(data);
			const response = await api.post("/registrar", data);
			console.log(response.data);
			const showToastMessage = () => {
				toast.success(`Cadastro realizado com sucesso!`, {
					position: toast.POSITION.TOP_CENTER,
				});
			};
			showToastMessage();
			history.push("/cadastrar");
		} catch (error) {
			console.log("erro ao cadastrar", error);
			console.log(error?.response.data.error);

			if (error?.response?.data?.error === "Usuário já existe com as informações informadas") {
				toast.error(`Usuário já existe com as informações informadas`, {
					position: toast.POSITION.TOP_CENTER,
				});
			} else {
				toast.error(`Erro ao cadastrar!`, {
					position: toast.POSITION.TOP_CENTER,
				});
			}
		}
		setLoading(false);
	};

	const specialtiesList = [
		"Acupuntura",
		"Agente comunitário de saúde",
		"Alergia e imunologia",
		"Anatomopatologista",
		"Anestesiologista",
		"Angiologia",
		"Assistente social",
		"Atendente de consultório dentário",
		"Auxiliar de enfermagem",
		"Auxiliar de farmácia de manipulação",
		"Auxiliar de laboratório de análises clínicas",
		"Auxiliar de radiologia (revelação fotográfica)",
		"Auxiliar em saúde bucal da estratégia de saúde da família",
		"Auxiliar técnico em patologia clínica",
		"Biólogo",
		"Biomédico",
		"Cancerologista cirúrgico (oncologista cirúrgico)",
		"Cardiologia",
		"Cirurgia cardiovascular",
		"Cirurgia da mão",
		"Cirurgia de cabeça e pescoço",
		"Cirurgia do aparelho digestivo",
		"Cirurgia geral",
		"Cirurgia pediátrica",
		"Cirurgia plástica",
		"Cirurgia torácica",
		"Cirurgia vascular",
		"Cirurgião dentista - clínico geral",
		"Cirurgião dentista - dentística",
		"Cirurgião dentista - endodontista",
		"Cirurgião dentista - estratégia saúde da família",
		"Cirurgião dentista - ortopedista e ortodontista",
		"Cirurgião dentista - patologista bucal",
		"Cirurgião dentista - radiologista",
		"Cirurgião dentista - traumatologista bucomaxilofacial",
		"Cirurgião dentista de saúde coletiva",
		"Clínica médica",
		"Clínico geral",
		"Curso de dependência química",
		"Dermatologia",
		"Diretor de serviços de saúde",
		"Educador físico",
		"Endocrinologia e metabologia",
		"Endoscopista",
		"Enfermeiro",
		"Farmacêutico",
		"Farmacêutico bioquímico",
		"Fisiatra",
		"Fisioterapeuta esportivo",
		"Fisioterapeuta geral",
		"Fonoaudiólogo",
		"Gastroenterologia",
		"Geneticista (médico)",
		"Geriatra",
		"Ginecologia e obstetrícia",
		"Hematologia",
		"Hemoterapeuta",
		"Hiperbarista",
		"Infectologista",
		"Instrumentador cirúrgico",
		"Intensivista",
		"Legista",
		"Massagista",
		"Mastologista",
		"Medicina estética",
		"Medicina nuclear",
		"Médico coloproctologista",
		"Médico de família e comunidade",
		"Médico do trabalho",
		"Médico em medicina de tráfego",
		"Médico em medicina preventiva e social",
		"Médico estratégia saúde da família",
		"Médico homeopata",
		"Médico patologista clínico / medicina laboratorial",
		"Médico residente",
		"Médico veterinário",
		"Musicoterapeuta",
		"Nefrologia",
		"Neurocirurgião",
		"Neurofisiologia clínica",
		"Neurologia",
		"Neuropsicólogo",
		"Nutricionista",
		"Nutrologia",
		"Odontologista",
		"Oftalmologista",
		"Oncologia",
		"Ortodontia e ortopedia facial",
		"Ortopedia e traumatologia",
		"Ortoptista",
		"Otorrinolaringologista",
		"Outras",
		"Patologista",
		"Pediatria",
		"Pneumologia",
		"Podologia",
		"Preparador físico",
		"Psicologia clínica",
		"Psicopedagogo",
		"Psiquiatria",
		"Radiologista / imagenologista",
		"Radioterapeuta",
		"Reumatologia",
		"Sanitarista",
		"Terapeuta",
		"Terapeuta ocupacional",
		"Trabalhador de serviços de limpeza e conservação de áreas públicas",
		"Técnico de enfermagem",
		"Técnico de enfermagem do trabalho",
		"Técnico de laboratório de análises físico-químicas (materiais de construção)",
		"Técnico em acupuntura",
		"Técnico em fotônica",
		"Técnico em higiene dental",
		"Técnico em patologia clínica",
		"Técnico em radiologia e imagenologia",
		"Urologia",
		"Visitador sanitário",
	];

	const formattedList = specialtiesList.map((especialidades) => {
		return { label: especialidades, value: especialidades };
	});

	const inputFields = [
		{
			name: "Nome completo",
		},
		{
			name: "Email",
			type: "Email",
		},
		{
			name: "Contato",
			mask: "(99) 99999-9999",
		},
		{
			name: "CPF",
			mask: "999.999.999-99",
		},
		{
			name: "CNPJ",
			mask: "99.999.999/9999-99",
		},
		{
			name: "Número de registro profissional",
		},
		{
			name: "Chave PIX",
		},
		{
			name: "Tipo chave",
			type: "select",
			placeholder: "Selecione um tipo de chave",
			list: [
				{ label: "CPF", value: "CPF" },
				{ label: "E-mail", value: "email" },
				{ label: "Telefone", value: "telefone" },
				{ label: "Chave Aleatória", value: "aleatoria" },
				{ label: "CNPJ", value: "CNPJ" },
			],
		},
		{
			name: "CEP",
			mask: "99999-999",
		},
		{
			name: "Endereço",
		},
		{
			name: "Bairro",
		},
		{
			name: "Cidade",
		},
		{
			name: "Estado",
		},
		{
			name: "Especialidades",
			type: "multi-select",
			placeholder: "Selecione uma especialidades",
			list: formattedList,
		},
		{
			name: "Senha",
			type: "password",
		},
		{
			name: "Confirmar senha",
			type: "password",
		},
	];

	const validationSchema = Yup.object().shape({
		"Nome completo": Yup.string().required("Campo obrigatório"),
		Email: Yup.string().email("Email inválido").required("Campo obrigatório"),
		Contato: Yup.string().required("Campo obrigatório"),

		CPF: Yup.string()
			.matches(/^.{14}$/, "CPF inválido")
			.required("Campo obrigatório"),
		CNPJ: Yup.string()
			.matches(/^.{18}$/, "CPF inválido")
			.required("Campo obrigatório"),
		"Número de registro profissional": Yup.string()
			.required("Campo obrigatório")
			.max(15, "Máximo de 15 caracteres permitidos"),
		CEP: Yup.string()
			.matches(/^\d{8}$/, "CEP inválido")
			.required("Campo obrigatório"),
		Endereço: Yup.string().required("Campo obrigatório"),
		Bairro: Yup.string().required("Campo obrigatório"),
		Cidade: Yup.string().required("Campo obrigatório"),
		Estado: Yup.string().required("Campo obrigatório"),
		Especialidades: Yup.array().required("Campo obrigatório"),
		Senha: Yup.string()
			.min(8, "A senha deve ter pelo menos 8 caracteres")
			.required("Campo obrigatório"),
		"Confirmar senha": Yup.string()
			.oneOf([Yup.ref("Senha"), null], "As senhas não coincidem")
			.required("Campo obrigatório"),
		"Tipo chave": Yup.string().required("Campo obrigatório"),
		"Chave PIX": Yup.string().required("Campo obrigatório"),
		Foto: Yup.mixed().required("Adicione a foto da identidade funcional"),
	});

	const handleCepBlur = async (event, setFieldValue) => {
		const cep = event.target.value.replace(/\D/g, "");

		if (cep.length === 8) {
			try {
				const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
				const { data } = response;
				if (!data.erro) {
					setFieldValue("CEP", cep);
					setFieldValue("Endereço", data.logradouro);
					setFieldValue("Bairro", data.bairro);
					setFieldValue("Cidade", data.localidade);
					setFieldValue("Estado", data.uf);
				} else {
					// Limpar os outros campos se o CEP for inválido ou não for encontrado
					setFieldValue("CEP", "");
					setFieldValue("Endereço", "");
					setFieldValue("Bairro", "");
					setFieldValue("Cidade", "");
					setFieldValue("Estado", "");

					console.log("CEP inválido ou não encontrado");
				}
			} catch (error) {
				// Tratar erros de requisição
				console.log("Erro ao buscar CEP", error);
			}
		} else {
			// Limpar os outros campos caso o CEP não tenha 8 dígitos
			setFieldValue("Endereço", "");
			setFieldValue("Bairro", "");
			setFieldValue("Cidade", "");
			setFieldValue("Estado", "");
		}
	};

	const [identityPhoto, setIdentityPhoto] = useState(null);
	const [profilePhoto, setProfilePhoto] = useState(null);

	const handleFileUpload = (file, setFieldValue) => {
		setIdentityPhoto(file);
		setFieldValue("Foto", file);
	};
	const handleFileUploadProfile = (file, setFieldValue) => {
		setProfilePhoto(file);
		setFieldValue("Perfil", file);
	};

	const handleChave = (event, setFieldValue) => {
		const type = event.value;
		setFieldValue("Tipo chave", type);
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			width: "36vw",
			minHeight: "45px",
			borderRadius: "10px",
			backgroundColor: "#fafafa",
			fontSize: "larger",
			color: "black",
			border: "1px solid #e7e7e7",
		}),
		option: (provided, state) => ({
			...provided,
			color: "black", // Cor do texto das opções
		}),
	};

	return (
		<Page>
			<SidebarMenu />
			<PageContainer>
				<PageTitle>Cadastrar Prestador</PageTitle>
				<Formik
					initialValues={{
						"Nome completo": "",
						Email: "",
						Contato: "",
						CPF: "",
						CNPJ: "",
						"Número de registro profissional": "",
						"Chave PIX": "",
						"Tipo chave": "",
						CEP: "",
						Endereço: "",
						Bairro: "",
						Cidade: "",
						Estado: "",
						Especialidades: "",
						Senha: "",
						"Confirmar senha": "",
						Foto: null,
						Perfil: null,
					}}
					onSubmit={(values) => {
						console.log("values", values);
						if (!!identityPhoto && !!profilePhoto) {
							sendData(values);
						}
					}}
					validationSchema={validationSchema}
				>
					{({ setFieldValue, handleBlur, values, errors }) => (
						<Form>
							<RegisterContainer>
								{inputFields.map((field, index) => {
									if (field.type === "select") {
										return (
											<>
												<CreatableSelect
													id={field.name}
													name={field.name}
													options={field.list}
													value={field.list.filter((element) =>
														element.value.includes(values[field.name])
													)}
													onChange={(e) => handleChave(e, setFieldValue)}
													styles={customStyles}
													placeholder={field.placeholder}
												/>
												<ErrorMessage name={field.name} component={<div>erro</div>}></ErrorMessage>
											</>
										);
									} else if (field.type === "multi-select") {
										return (
											<>
												<CreatableSelect
													id={field.name}
													name={field.name}
													options={field.list}
													isMulti
													value={values[field.name]}
													onChange={(e) => {
														setFieldValue(field.name, e);
														console.log(values);
													}}
													styles={customStyles}
													placeholder={field.placeholder}
												/>
												<ErrorMessage name={field.name} component={<div>erro</div>}></ErrorMessage>
											</>
										);
									} else {
										return (
											<div key={index}>
												<ColumnRow1>
													<Input
														type={field.type || "text"}
														name={field.name}
														placeholder={field.name}
														mask={field.mask}
														maskChar={null}
														required
														onBlur={
															field.name === "CEP"
																? (e) => handleCepBlur(e, setFieldValue)
																: handleBlur
														}
													></Input>
												</ColumnRow1>
											</div>
										);
									}
								})}
								<IdentityPhotoUploader
									text={
										"Arraste e solte a foto da identidade funcional aqui, ou clique para selecionar um arquivo."
									}
									onFileUpload={(file) => {
										handleFileUpload(file, setFieldValue);
									}}
								/>

								<ErrorMessage
									name="Foto"
									component="div"
									style={{
										color: "red",
										paddingLeft: "10px",
										fontSize: "12px",
										fontWeight: "bold",
									}}
								/>
								<IdentityPhotoUploader
									text={
										"Arraste e solte a foto de perfil aqui, ou clique para selecionar um arquivo."
									}
									onFileUpload={(file) => {
										handleFileUploadProfile(file, setFieldValue);
									}}
								/>

								<ErrorMessage
									name="Foto"
									component="div"
									style={{
										color: "red",
										paddingLeft: "10px",
										fontSize: "12px",
										fontWeight: "bold",
									}}
								/>
								<RegisterButton type="submit" onClick={() => console.log(errors)}>
									{loading ? (
										<ReactLoading type="spin" color="#B2EBF2" height={"30px"} width={"30px"} />
									) : (
										"Cadastrar"
									)}
								</RegisterButton>
							</RegisterContainer>
						</Form>
					)}
				</Formik>
			</PageContainer>
		</Page>
	);
}
