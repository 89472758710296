import React, { useState, useEffect } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import {
  RegisterContainer,
  RegisterInput,
  ErrorText,
  RegisterButton,
} from "../CadastrarMedico/styles";
import { PageTitle} from "../Creditos/styles";
import ReactLoading from "react-loading";
import api from "../../../Services/api";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../Components/input";
import { ButtonRow, LoginRow } from "../../SignedOut/Login/styles";
import PageContainer from "../../../Components/PageContainer";

export default function CadastrarVendedor() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [cpf, setCpf] = useState(""); 
  const [invalidFields, setInvalidFields] = useState(false);
  const [passwordDiff, setPasswordDiff] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const initialValues = {
    Email: "",
    Senha: "",
    ConfirmarSenha: "",
    Nome: "",
    CPF: "", 
  }

  const validationSchema = Yup.object({
    Email: Yup.string().email("E-mail Inválido").required("Campo Obrigatório"),
    Senha: Yup.string()
      .min(8, "A senha deve conter pelo menos 8 caracteres")
      .required("Campo Obrigatório"),
    ConfirmarSenha: Yup.string()
      .min(8, "A senha deve conter pelo menos 8 caracteres")
      .required("Campo Obrigatório")
      .oneOf([Yup.ref("Senha")], "As senhas precisam ser iguais"),
    Nome: Yup.string().required("Campo Obrigatório"),
    CPF: Yup.string().required("Campo Obrigatório"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        nome: values.Nome,
        email: values.Email,
        senha: values.Senha, 
        tipo: "vendedor",
        vendedor: {cpf: values.CPF},
      };
      const response = await api.post("/registrar", data);
      const showToastMessage = () => {
        toast.success(`Cadastro realizado com sucesso!`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
      history.push("/cadastrar");
    } catch (error) {
      console.log(error);
      const showToastMessage = () => {
        toast.error(`Erro ao cadastrar!!`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
    }
    setLoading(false);
  };

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Cadastrar vendedor</PageTitle>
        <RegisterContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, isSubmitting }) => (
              <Form>
                <LoginRow>
                  <Input
                    name="Nome"
                    type="text"
                    required
                    value={values.Nome}
                  ></Input>
                </LoginRow>
                <LoginRow>
                  <Input
                    name="Email"
                    type="email"
                    required
                    value={values.Email}
                  ></Input>
                </LoginRow>
                <LoginRow>
                <Input
  name="CPF"
  type="text"
  required
  value={values.CPF}
  onChange={handleChange}
  mask="999.999.999-99"
  maskChar={null}
></Input>
                </LoginRow>
                <LoginRow>
                  <Input
                    name="Senha"
                    type="password"
                    required
                    value={values.Senha}
                  ></Input>
                </LoginRow>
                <LoginRow>
                  <Input
                    name="ConfirmarSenha"
                    type="password"
                    required
                    value={values.ConfirmarSenha}
                  ></Input>
                </LoginRow>
                <ButtonRow>
                  <RegisterButton disabled={isSubmitting} type="submit">
                    {loading ? (
                      <ReactLoading
                        type="spin"
                        color="#B2EBF2"
                        height={"30px"}
                        width={"30px"}
                      />
                    ) : (
                      "Cadastrar"
                    )}
                  </RegisterButton>
                  <ErrorText>
                    {passwordDiff ? "As senhas inseridas não coincidem" : ""}
                  </ErrorText>
                </ButtonRow>
              </Form>
            )}
          </Formik>
        </RegisterContainer>
      </PageContainer>
    </Page>
  );
}
