import FooterLP from "../../../Components/FooterLP";
import HeaderLP from "../../../Components/HeaderLP";
import MainLP from "../../../Components/MainLP";


const LandingPage = () => {
    return(
        <div className="flex flex-col max-w-screen h-screen bg-roxoClaro justify-between">
            <HeaderLP/>
            <MainLP/>
            <FooterLP/>
        </div>
    )
}

export default LandingPage;