import React, { useEffect, useState } from "react";
import { Card } from "./styles";
import { PageOptions } from "./styles";

export default function MetricInforCard(props) {
	return (
		<Card>
			{props.data.map((item, key) => {
				return (
					<div className="flex flex-col justify-between items-center h-[125px] w-[15%]" key={key}>
						<div className="flex items-center h-[75px] ">{item.icon}</div>
						<div className="flex flex-col justify-center items-center h-[50px]">
							<p className="not-italic font-bold text-2xl leading-[30px] text-[#067187]">
								{item.value}
							</p>
							<div className="inline-block text-nowrap h-5 not-italic font-semibold text-base leading-5 text-[#929292]">
								{item.title}
							</div>
						</div>
					</div>
				);
			})}
		</Card>
	);
}
