import React, { useEffect, useState } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { MenuItem, PageTitle, Option, MenuLink, Menu, OptionTitle } from "./styles";
import PageContainer from "../../../Components/PageContainer";
import Chart from "react-apexcharts";
import { PageOptions } from "./styles";
import api from "../../../Services/api";
import {
	downloadsInfo,
	optionsAcessosMes,
	optionsDownloadsMes,
	optionsPacientes,
	optionsPrestadores,
	pacientesInfo,
	prestadoresInfo,
} from "./data";
import MenuMetricas from "../../../Components/MenuMetricas";
import MetricInforCard from "../../../Components/MetricInfoCard";

export default function MetricasAcessos() {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getCredits = async () => {
			setLoading(true);
			try {
				const response = await api.get("/creditos/total");
				const { total_creditos_plataforma } = response.data;
				console.log(total_creditos_plataforma);
				console.log(response.data);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getCredits();
	}, []);

	return (
		<Page>
			<SidebarMenu />
			<PageContainer>
				<PageTitle>Métricas</PageTitle>
				<PageOptions>
					<MenuMetricas></MenuMetricas>

					<Option>
						<OptionTitle>Site</OptionTitle>
						<Chart
							options={optionsAcessosMes.options}
							series={optionsAcessosMes.series}
							type="line"
							height={350}
						></Chart>
					</Option>
					<Option>
						<OptionTitle>Downloads por mês</OptionTitle>
						<MetricInforCard data={downloadsInfo}></MetricInforCard>
						<Chart
							options={optionsDownloadsMes.options}
							series={optionsDownloadsMes.series}
							type="line"
							height={350}
						></Chart>
					</Option>
					<Option>
						<OptionTitle>Pacientes</OptionTitle>
						<MetricInforCard data={pacientesInfo}></MetricInforCard>
						<Chart
							options={optionsPacientes.options}
							series={optionsPacientes.series}
							type="line"
							height={350}
						></Chart>
					</Option>
					<Option>
						<OptionTitle>Prestadores</OptionTitle>
						<MetricInforCard data={prestadoresInfo}></MetricInforCard>
						<Chart
							options={optionsPrestadores.options}
							series={optionsPrestadores.series}
							type="line"
							height={350}
						></Chart>
					</Option>
				</PageOptions>
			</PageContainer>
		</Page>
	);
}
