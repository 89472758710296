import React, { useEffect, useState} from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import api from '../../../Services/api';

import Sidebar from "../../../Components/Sidebar";
import {Page, PageContainer, InputContainer, PageTitle} from '../Cadastro/styles';
import {RedirectContainer, RedirectButton, LoginRow, ButtonRow} from '../Login/styles';
import {PasswordResetContainer, EmailInput, SendButton, PasswordInfo} from './styles';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../Components/input";

export default function ForgotPassword(){
    const history = useHistory();
    const [loading, setLoading] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);

    const initialValues = {
        Email:"",
    };
    const validationSchema = Yup.object({
        Email: Yup.string()
        .email("E-mail Inválido").required("Campo Obrigatório")

    });

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const data = {
                email: values.Email,
            };
            const response = await api.post("/esqueci-senha", data);
            console.log(response);
            history.push({ 
                pathname: '/resetar-senha',
                state: values.Email
               });
            
            const showToastMessage = () => {
                toast.success(`As instruções para redefinir sua senha foram enviadas para o seu email!`, {
                    position: toast.POSITION.TOP_CENTER
                });
            };
            showToastMessage();

        } catch (error) {
            console.log(error);
            const showToastMessage = () => {
                toast.error(`Email não encontrado`, {
                    position: toast.POSITION.TOP_CENTER
                });
            };
            showToastMessage();
            console.log("sadsad")
            
        }
        setLoading(false);
    };

    
    return(
        <Page>
            <Sidebar/>
            <PageContainer>
                <PasswordResetContainer>
                    <PageTitle>Esqueci minha senha</PageTitle>
                    <PasswordInfo>
                        Caso tenha esquecido sua senha, digite seu email abaixo para enviarmos um link de redefinição.
                    </PasswordInfo>
                    <InputContainer>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        >   
                        {({values,
                            errors,
                            touched,
                            handleChange,
                            isSubmitting,}) => (
                            <Form>
                               <LoginRow>
                                <Input name="Email" type="email" required placeholder="Email" onChange={handleChange} value={values.Email}></Input>
                                </LoginRow>
                                <ButtonRow>
                                <SendButton type='submit' disabled={isSubmitting}>
                            {loading ?
                                <ReactLoading type="spin" color="#B2EBF2" height={"30px"} width={"30px"}/> :
                                "Enviar"
                            }
                        </SendButton>
                        </ButtonRow>
                            </Form>
                        )}
                            
                        </Formik>

                        <ToastContainer />
                    </InputContainer>
                    <RedirectContainer>
                        <NavLink to='/'> <RedirectButton>Voltar à tela de login</RedirectButton> </NavLink>
                    </RedirectContainer>
                </PasswordResetContainer>
            </PageContainer>
        </Page>
    ) 
}