import React, { useEffect, useState } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { MenuItem, PageTitle, Option, Menu, OptionTitle } from "./styles";
import PageContainer from "../../../Components/PageContainer";
import Chart from "react-apexcharts";
import { PageOptions } from "./styles";
import api from "../../../Services/api";
import MenuMetricas from "../../../Components/MenuMetricas";
import { optionsDependentes, optionsGenero, optionsTitulares } from "./data";

export default function MetricasUsers() {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getCredits = async () => {
			setLoading(true);
			try {
				const response = await api.get("/creditos/total");
				const { total_creditos_plataforma } = response.data;
				console.log(total_creditos_plataforma);
				console.log(response.data);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getCredits();
	}, []);

	return (
		<Page>
			<SidebarMenu />
			<PageContainer>
				<PageTitle>Metricas</PageTitle>
				<PageOptions>
					<MenuMetricas></MenuMetricas>
					<Option>
						<OptionTitle>Gênero</OptionTitle>
						<div className="flex justify-center items-center h-fit py-8 w-4/5 bg-brancoID rounded-[20px]">
							<Chart
								options={optionsGenero.options}
								series={optionsGenero.series}
								type="donut"
								height={250}
								width={550}
							></Chart>
						</div>
					</Option>
					<Option>
						<OptionTitle>Distribuição Etária</OptionTitle>
						<div className="flex justify-center items-center h-fit py-8 w-4/5 bg-brancoID rounded-[20px]">
							<Chart
								options={optionsTitulares.options}
								series={optionsTitulares.series}
								type="donut"
								height={250}
								width={550}
							></Chart>
						</div>
						<div className="flex justify-center items-center h-fit py-8 w-4/5 bg-brancoID rounded-[20px]">
							<Chart
								options={optionsDependentes.options}
								series={optionsDependentes.series}
								type="donut"
								height={250}
								width={550}
							></Chart>
						</div>
					</Option>
				</PageOptions>
			</PageContainer>
		</Page>
	);
}
