import React, { useState, useEffect } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Link, useParams } from "react-router-dom";
import api from "../../../Services/api";
import { useAuth } from "../../../Hooks/useAuth";
import MainButton from "../../../Components/MainButton";
import {
	Content,
	Info,
	InfoBlock,
	InfoTitle,
	PacientInfo,
	PageTitle,
	Redefinir,
	PacientRow,
	MissingInfo,
	RightDiv,
	PageContainerDiv,
	Background,
	InputEdit,
} from "./styles";
import img from "./assets/back.png";
import { toast, ToastContainer } from "react-toastify";

function Perfil() {
	const { user } = useAuth();
	const { id } = useParams();
	const [dados, setDados] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [nome, setNome] = useState("");
	const [email, setEmail] = useState("");
	const [endereco, setEndereco] = useState("");
	const [bairro, setBairro] = useState("");
	const [cidade, setCidade] = useState("");
	const [estado, setEstado] = useState("");
	const [twitterUrl, setTwitterUrl] = useState("");
	const [instagramUrl, setInstagramUrl] = useState("");
	const [facebookUrl, setFacebookUrl] = useState("");
	const [creditosConsulta, setCreditosConsulta] = useState("");

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await api.get(`/user/${user.id}`);
				setDados(response.data);
				setNome(response.data.nome);
				setEmail(response.data.email);
				setEndereco(response.data.medico.endereco);
				setBairro(response.data.bairro);
				setCidade(response.data.cidade);
				setEstado(response.data.estado);
				setTwitterUrl(response.data.medico.twitter_url);
				setInstagramUrl(response.data.medico.instagram_url);
				setFacebookUrl(response.data.medico.facebook_url);
				setCreditosConsulta(response.data.creditos_consulta);
			} catch (error) {
				console.log(error, "Erro no perfil");
			}
		};

		fetchUserData();
	}, [user.id]);

	const handleEdit = async () => {
		try {
			const response = await api.patch(`/user/${user.id}`, {
				nome,
				email,
				bairro,
				cidade,
				estado,
				tipo: "medico",
				medico: {
					endereco: endereco ? endereco : "",
					twitter_url: twitterUrl ? twitterUrl : "",
					instagram_url: instagramUrl ? instagramUrl : "",
					facebook_url: facebookUrl ? facebookUrl : "",
					creditos_consulta: creditosConsulta ? creditosConsulta : 0,
				},
			});

			setDados(response.data);
			setEditMode(false);
			toast.success("Perfil editado com sucesso");
		} catch (error) {
			console.log(error, "Erro ao editar o perfil");
			toast.error("Erro ao editar perfil");
		}
	};

	return (
		<PageContainerDiv>
			<SidebarMenu />
			<Background>
				<RightDiv>
					<PageTitle>Perfil</PageTitle>
					<Content>
						<PacientInfo>
							<InfoBlock>
								<InfoTitle>Nome:</InfoTitle>
								{editMode ? (
									<InputEdit type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
								) : (
									<Info>{dados.nome}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Email:</InfoTitle>
								{editMode ? (
									<InputEdit
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								) : (
									<Info>{dados.email}</Info>
								)}
							</InfoBlock>
							{user.tipo !== "clinica" && (
								<>
									<InfoBlock>
										<InfoTitle>Twitter:</InfoTitle>
										{editMode ? (
											<InputEdit
												type="text"
												value={twitterUrl}
												onChange={(e) => setTwitterUrl(e.target.value)}
											/>
										) : (
											<MissingInfo>
												{twitterUrl ? <Info>{twitterUrl}</Info> : "Edite a URL do seu Twitter"}
											</MissingInfo>
										)}
									</InfoBlock>
									<InfoBlock>
										<InfoTitle>Instagram:</InfoTitle>
										{editMode ? (
											<InputEdit
												type="text"
												value={instagramUrl}
												onChange={(e) => setInstagramUrl(e.target.value)}
											/>
										) : (
											<a href={instagramUrl} target="_blank" rel="noopener noreferrer">
												<MissingInfo>
													{instagramUrl ? (
														<Info>{instagramUrl}</Info>
													) : (
														"Edite a URL do seu Instagram"
													)}
												</MissingInfo>
											</a>
										)}
									</InfoBlock>
									<InfoBlock>
										<InfoTitle>Facebook:</InfoTitle>
										{editMode ? (
											<InputEdit
												type="text"
												value={facebookUrl}
												onChange={(e) => setFacebookUrl(e.target.value)}
											/>
										) : (
											<a href={facebookUrl} target="_blank" rel="noopener noreferrer">
												<MissingInfo>
													{facebookUrl ? <Info>{facebookUrl}</Info> : "Edite a URL do seu Facebook"}
												</MissingInfo>
											</a>
										)}
									</InfoBlock>
								</>
							)}
						</PacientInfo>
						<PacientInfo>
							<InfoBlock>
								<InfoTitle>Endereço</InfoTitle>
								{editMode ? (
									<InputEdit
										type="text"
										value={endereco}
										onChange={(e) => setEndereco(e.target.value)}
									/>
								) : (
									<Info>{dados.medico?.endereco}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Bairro</InfoTitle>
								{editMode ? (
									<InputEdit
										type="text"
										value={bairro}
										onChange={(e) => setBairro(e.target.value)}
									/>
								) : (
									<Info>{dados.bairro}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Cidade</InfoTitle>
								{editMode ? (
									<InputEdit
										type="text"
										value={cidade}
										onChange={(e) => setCidade(e.target.value)}
									/>
								) : (
									<Info>{dados.cidade}</Info>
								)}
							</InfoBlock>
							<InfoBlock>
								<InfoTitle>Estado</InfoTitle>
								{editMode ? (
									<InputEdit
										type="text"
										value={endereco}
										onChange={(e) => setEstado(e.target.value)}
									/>
								) : (
									<Info>{dados.estado}</Info>
								)}
							</InfoBlock>

							<InfoBlock>
								<InfoTitle>Valor da consulta:</InfoTitle>
								{editMode ? (
									<InputEdit
										type="text"
										value={creditosConsulta}
										onChange={(e) => setCreditosConsulta(e.target.value)}
									/>
								) : (
									<Info>{dados.medico?.creditos_consulta}</Info>
								)}
							</InfoBlock>
						</PacientInfo>
					</Content>
					{editMode ? (
						<Redefinir>
							<MainButton onClick={handleEdit}>Salvar</MainButton>
							<MainButton onClick={() => setEditMode(false)}>Cancelar</MainButton>
						</Redefinir>
					) : (
						<Redefinir>
							<MainButton onClick={() => setEditMode(true)}>Editar Perfil</MainButton>
							<Link to="/redefinicao-de-senha">
								<MainButton>Redefinir senha</MainButton>
							</Link>
						</Redefinir>
					)}
				</RightDiv>
			</Background>
		</PageContainerDiv>
	);
}

export default Perfil;
